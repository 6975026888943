document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll(".toggle_password_icon").forEach(function(el) {
    el.addEventListener("click", function() {
      var passwordField= this.nextElementSibling;
      console.log(passwordField);
      const type = passwordField.getAttribute("type") === "password" ? "text" : "password";
      passwordField.setAttribute("type", type);
      this.classList.toggle("bi-eye");
    });
  })

  document.querySelectorAll(".toggle_password_field").forEach(function(el) {
    el.addEventListener("keyup", function(e) {
      validatePassword(el, this);
    });
  })

  function validatePassword(password, el) {

    var error = false;

    var length = validateLength(password, document.querySelector(".min_length"));
    var custom = validateCustom(password, document.querySelector(".min_custom_characters"));
    var upper = validateUpperCase(password, document.querySelector(".min_upper_case"));
  }

  function activateOption(el) {
    el.classList.add('valid');
  }

  function disableOption(el) {
    el.classList.remove('valid');
  }

  function validateCustom(pw, el) {
    if(pw.value.match(/([-!§$%&/()=?*_:;,.#+@€|ÄÜÖäöüß])/)) {
      activateOption(el);
      return true;
    } else {
      disableOption(el);
      return false;
    }
  }

  function validateUpperCase(pw, el) {
    var upperCaseLetters = /[A-Z]/g;
    if(pw.value.match(upperCaseLetters)) {
      activateOption(el);
      return true;
    } else {
      disableOption(el);
      return false;
    }
  }

  function validateLength(pw, el) {
    if(pw.value.length >= 8) {
      activateOption(el);
      return true
    } else {
      disableOption(el);
      return false;
    }
  }
});
